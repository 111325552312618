import React, { useState } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import { FileUpload } from 'components/common/FileUpload/file-upload.component';
import { DataObject } from 'types';
import { useTooltipTour, TooltipTour } from 'components/common/TooltipTour';
import { ButtonBase, Snackbar, Typography } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { store } from 'store';
import ShowIf from 'components/views/ShowIf';
import { DialogPage } from 'components/common/DialogPage';
import { OrdersPrekursorDownloadIcon } from 'components/common/BottomNav/nav-icons/orders-icon/orders-prekursor-download-icon';
import { OrderPrecursorStatus } from 'services';
import { Alert } from '@material-ui/lab';
import { isWebviewIos } from 'utils/helpers/check-platform';
import { createPortal } from 'react-dom';
import { AnalyticsEvent, makeAnalyticsRequest } from 'utils/Analytics/Segment';
import CenteredItem from '../../../../views/CenteredItem';
import { OrderDetails, Order } from '../../../../../store/reducers/Orders';
import { TranslateProps } from '../../../../../utils/Localization/types';

import { PrekursorDialogSuccess } from './PrekursorDialogSuccess.component';
import { PrekursorEditDocument } from './PrekursorModaEditDocument.component';
import { PrekursorDialogFailed } from './PrekursorDialogFailed.component';
import { S } from './PrekursorDocument.style';
import { useOrderPrekursorStatus } from './useOrderPrekursorStatus';

export interface Props {
  order: OrderDetails | Order | DataObject<any>;
  disableTitle?: boolean;
  style?: CSSProperties;
  onClose?: () => void;
  digitalSignatureVerified?: boolean;
  onHandleShowOtpFrame?: () => void;
}

export const PrekursorDocument: React.FC<React.PropsWithChildren<Props & TranslateProps>> = (props) => {
  const {
    order,
    disableTitle = false,
    t,
    style,
    onClose: onPrekursorDocumentClose,
    digitalSignatureVerified = false,
    onHandleShowOtpFrame,
  } = props;
  const { precursor_status } = order;
  const {
    loading: apiLoading,
    disabled,
    error: uploadError,
    refetchPrekursorStatus,
    uploadPrekursorDocument,
    spLoading,
    checkSPDocument,
  } = useOrderPrekursorStatus(order);
  const { tooltipParams: preUploadTooltipParams, visible: preUploadVisible } = useTooltipTour(
    'prekursorTooltipTour.preUpload',
    'right',
  );
  const { tooltipParams: postUploadTooltipParams, visible: postUploadVisible } = useTooltipTour(
    'prekursorTooltipTour.postUpload',
    'right',
  );

  const [shouldShowDocuementDetail, showDocumentDetail] = useState(false);
  const [isUploading, setUploading] = useState(false);
  const [prekursorFileName, setPrekursorFilename] = useState('');
  const isFinishUploading = !apiLoading && isUploading;
  const isDSDownloadDisabled =
    digitalSignatureVerified && precursor_status?.status !== OrderPrecursorStatus.DOCUMENT_APPROVED;
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const onClose = () => {
    setOpen(false);
  };

  const handleUpload = (files) => {
    if (files.length) {
      setUploading(true);
      setPrekursorFilename(files[0].name);
      uploadPrekursorDocument(order as OrderDetails, files[0]);
      if (onHandleShowOtpFrame) onHandleShowOtpFrame();
    }
  };

  const [showInstruction, setShowInstruction] = useState(false);

  const state = store.getState();

  const uploadSuccess = !uploadError;

  if (disabled) {
    return null;
  }

  const isDocumentUploaded = precursor_status?.status !== OrderPrecursorStatus.DOCUMENT_REQUIRED;
  const fileUploadArea = (): JSX.Element => (
    <FileUpload
      setOpen={setOpen}
      setMessage={setMessage}
      title={t('prekursor.add')}
      loading={apiLoading}
      handleFile={handleUpload}
      t={t}
    />
  );

  const handleShowDocumentDetail = () => {
    showDocumentDetail(true);
    makeAnalyticsRequest('pt_precursor_open_edit_document_page', AnalyticsEvent.Page, {
      poNumber: order.po_number,
      pharmacyId: order.pharmacy_id,
      status: order.precursor_status?.status,
    });
  };

  const uploadArea = (): JSX.Element => {
    if (isDocumentUploaded) {
      return (
        <S.Uploaded onClick={() => handleShowDocumentDetail()}>
          {precursor_status?.document_url?.endsWith('.pdf') ? (
            <>
              <br />
              <br />
              <p style={{ fontWeight: 'bold', fontSize: '2em' }}>E-SP</p>
              <br />
              <br />
            </>
          ) : (
            <img src={precursor_status?.document_url} alt="Document" />
          )}
          <S.Subtitle>Tap here to preview</S.Subtitle>
        </S.Uploaded>
      );
    }

    return fileUploadArea();
  };

  const handleCloseDocumentDetail = (): void => {
    showDocumentDetail(false);
    if (onPrekursorDocumentClose) onPrekursorDocumentClose();
  };

  return (
    <div>
      <Card square className="header" elevation={0} style={style}>
        <CardContent>
          {!disableTitle && (
            <Grid className="distributor" container justify="space-between" direction="row">
              <CenteredItem>
                <S.Title className="name" variant="h2">
                  {t('prekursor.title')}
                </S.Title>
              </CenteredItem>
            </Grid>
          )}
          <S.PrekursorUploadSection className="details" container justify="space-between" direction="row">
            <S.Container>
              {!isDocumentUploaded && <S.UploadDot />}
              <S.UploadSection>{uploadArea()}</S.UploadSection>
              <div ref={postUploadTooltipParams.setTriggerRef} />
              <div ref={preUploadTooltipParams.setTriggerRef} />
            </S.Container>
            {precursor_status?.status === OrderPrecursorStatus.DOCUMENT_REJECTED && (
              <S.Container>
                <S.UploadDot />
                <S.UploadSection>{fileUploadArea()}</S.UploadSection>
                <div ref={postUploadTooltipParams.setTriggerRef} />
                <div ref={preUploadTooltipParams.setTriggerRef} />
              </S.Container>
            )}
            <Grid item xs={4} container alignItems="flex-end" justify="flex-end">
              <Grid container alignItems="center" justify="space-between">
                <Grid item>
                  <S.UploadInstructionInfoIcon />
                </Grid>
                <Grid item>
                  <ButtonBase onClick={() => setShowInstruction(true)}>
                    <S.UploadInstructionText>{t('prekursor.instruction')}</S.UploadInstructionText>
                  </ButtonBase>
                </Grid>
                <Grid item>
                  <S.UploadInstructionArrowIcon />
                </Grid>
              </Grid>
            </Grid>
          </S.PrekursorUploadSection>
          {(!isWebviewIos() || state.config.market.precursorIosDownload) && (
            <Grid className="download-template" container justifyContent="center" direction="row">
              <Grid item style={{ textAlign: 'center' }} xs={12}>
                <S.DownloadButton
                  disabled={spLoading || isDSDownloadDisabled}
                  dsDownloadDisabled={isDSDownloadDisabled}
                  onClick={() => checkSPDocument(order.id)}
                >
                  <S.OrdersPrekursorDownloadIconStyle>
                    <OrdersPrekursorDownloadIcon {...(isDSDownloadDisabled ? { fill: '#C5CAD5' } : {})} />
                  </S.OrdersPrekursorDownloadIconStyle>
                  {digitalSignatureVerified ? t('prekursor.downloadESP') : t('prekursor.downloadSPSample')}
                </S.DownloadButton>
              </Grid>
            </Grid>
          )}
          {isWebviewIos() && !state.config.market.precursorIosDownload && (
            <Alert severity="warning">{t('prekursor.sendSPViaEmail')}</Alert>
          )}
        </CardContent>
      </Card>
      <PrekursorDialogSuccess
        show={isFinishUploading && uploadSuccess}
        onClose={(close) => setUploading(close)}
        t={t}
      />
      <PrekursorDialogFailed
        show={isFinishUploading && !uploadSuccess}
        onClose={(close) => {
          setUploading(close);
          refetchPrekursorStatus(order.id);
        }}
        error={uploadError ?? 'Error'}
        fileName={prekursorFileName}
        t={t}
      />
      {order.precursor_status && (
        <Modal open={shouldShowDocuementDetail} onClose={handleCloseDocumentDetail}>
          <PrekursorEditDocument
            onUpload={handleUpload}
            precursorStatus={order.precursor_status}
            close={handleCloseDocumentDetail}
            t={t}
            setOpen={setOpen}
            setMessage={setMessage}
            digitalSignatureVerified={digitalSignatureVerified}
          />
        </Modal>
      )}
      <TooltipTour
        name="prekursorTooltipTour.preUpload"
        tooltipParams={preUploadTooltipParams}
        visible={preUploadVisible && !precursor_status?.document_url}
        width={200}
        offsetY={-20}
        zIndex={6}
      >
        <Typography>{t('prekursor.prekursorTooltipTour.preUpload')}</Typography>
      </TooltipTour>
      <TooltipTour
        name="prekursorTooltipTour.postUpload"
        tooltipParams={postUploadTooltipParams}
        visible={postUploadVisible && !!precursor_status?.document_url}
        width={200}
        offsetY={-20}
        zIndex={6}
      >
        <Typography>{t('prekursor.prekursorTooltipTour.postUpload')}</Typography>
      </TooltipTour>
      <ShowIf condition={showInstruction}>
        <DialogPage
          open={showInstruction}
          link={state.config.market.prekursorPageUrl}
          close={() => setShowInstruction(false)}
        />
      </ShowIf>
      {createPortal(
        <Snackbar
          ContentProps={{ style: { background: '#EB5757' } }}
          open={open}
          onClose={onClose}
          autoHideDuration={3000}
          message={message}
        />,
        document.body,
      )}
    </div>
  );
};

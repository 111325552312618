import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import * as colors from 'components/styles/colors';

export const S = {
  Wrapper: styled.div`
    background-color: ${colors.WHITE};
    border-radius: 10px;
    box-shadow: 0px 0px 1px rgba(99, 106, 122, 0.12), 0px 2px 10px rgba(58, 62, 71, 0.22);
    overflow: hidden;
  `,
  Header: styled.div`
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    background-color: ${colors.WHITE};
  `,
  Content: styled.div`
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
  `,
  Title: styled(Typography)`
    font-family: 'Nunito Sans', sans-serif;
    font-size: 18px;
    font-weight: 700;
    color: ${colors.PRIMARY_COLOR};
    && {
      margin-left: 5px;
    }
  `,
  Message: styled(Typography)`
    font-family: 'Nunito Sans', sans-serif;
    font-size: 14px;
    color: ${colors.GRAY31};
    && {
      font-weight: 700;
      margin-left: 20px;
    }
  `,
  OrderListTitle: styled(Typography)`
    font-family: 'Nunito Sans', sans-serif;
    font-size: 14px;
    font-weight: 700;
    color: ${colors.BLACK};
    margin-top: 16px;
  `,
  OrderNumber: styled(Typography)`
    font-family: 'Nunito Sans', sans-serif;
    font-size: 14px;
    color: ${colors.GRAY31};
    && {
      font-weight: 700;
      margin-left: 20px;
    }
  `,
  CheckCircleIcon: styled(CheckCircleIcon)`
    && {
      font-size: 18px;
      color: ${colors.PRIMARY_COLOR};
    }
  `,
};

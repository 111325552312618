export enum ERROR_CODE_ENUM {
  DIGIO_ERROR = 'DigioError',
  PRODUCT_UNPURCHASEABLE_ERROR = 'PRODUCT_UNPURCHASEABLE_ERROR',
  CREATE_FAILED_ACTIVE_EXIST = 'CREATE_FAILED_ACTIVE_EXIST',
  PHARMACY_SUSPENDED_ERROR = 'PharmacySuspended',
  MAX_PURCHASE_LIMIT_REACHED = 'MAX_PURCHASE_LIMIT_REACHED',
  MAX_SKU_LIMIT_REACHED = 'MAX_SKU_LIMIT_REACHED',
  UPLOAD_PRECURSOR_APPROVED_FAILED = 'UPLOAD_PRECURSOR_APPROVED_FAILED',
}

export enum OTP_ERROR_CODE_ENUM {
  OTP_INVALID = 'OTP_INVALID',
  OTP_AUTHENTICATED = 'OTP_AUTHENTICATED',
  OTP_EXPIRED = 'OTP_EXPIRED',
  OTP_MAX_RETRIES = 'OTP_MAX_RETRIES',
  OTP_MAX_INVALID_ATTEMPTS = 'OTP_MAX_INVALID_ATTEMPTS',
  OTP_DIFFERENT_ORDERS = 'OTP_DIFFERENT_ORDERS',
  PO_ALREADY_ACCEPTED = 'PO_ALREADY_ACCEPTED',
  SEND_MESSAGE_ERROR = 'SEND_MESSAGE_ERROR',
}

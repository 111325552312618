import React from 'react';
import { translate } from 'react-i18next';
import Grid from '@material-ui/core/Grid';

import { TranslateProps } from 'utils/Localization';
import { S } from './otp-verified.styles';

export interface DigitalSignatureVerifiedProps extends TranslateProps {
  orderNumberList: any;
}

export const DsVerifiedBase: React.FC<React.PropsWithChildren<DigitalSignatureVerifiedProps>> = (props) => {
  const { orderNumberList, t } = props;

  return (
    <S.Wrapper>
      <S.Header>
        <Grid container direction="row">
          <S.CheckCircleIcon />
          <S.Title align="left">Verified!</S.Title>
        </Grid>
      </S.Header>
      <S.Content>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <S.Message align="left">{t('submitOtpSuccess')}</S.Message>
          </Grid>
          <Grid item>
            {Array.isArray(orderNumberList) &&
              orderNumberList.map((po_number) => <S.OrderNumber align="left">{po_number}</S.OrderNumber>)}
          </Grid>
        </Grid>
      </S.Content>
    </S.Wrapper>
  );
};

export const DigitalSignatureVerified = translate('digitalSignature')(DsVerifiedBase);
